.banner {
	padding: .5em 1em;
	background: #fff;
	-webkit-box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
	-moz-box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
	box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
  position: relative;
}

.navbar {
  padding: 0;
}

.navbar-brand {
	margin-right: 0;
	font-size: 1rem;
}

.navbar-brand img {
	width: 150px;
}

.navbar-toggler {
	background: #004881;
  color: #fff;
  padding: .75em;
  margin-top: 4px;
	font-family: 'Titillium Web', sans-serif;
	font-weight: 600;
	font-size: 1em;
	right: 0;
}

#menu-huvudmeny .nav-link {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
}

#menu-huvudmeny .active .nav-link {
	color: #fff;
  cursor: default;
  background-color: #004881;
}

#menu-huvudmeny .active .nav-link:hover {
	color: #fff;
	text-decoration: underline;
}

#menu-huvudmeny .nav-link:hover {
  color: #b20933;
	text-decoration: underline;
}

@include media-breakpoint-down(sm) {
	#menu-huvudmeny {
		margin-top: .5em;
		flex-direction: column;
	}
}

@include media-breakpoint-up(md) {
	.banner {
		padding: 1em;
	}
	.navbar-brand img {
		width: 300px;
	}
}