body {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	background: #f5f5f5;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 600;
}

.title {
	background: #004881;
	padding: 1.4em;
	text-align: center;
}

.title h1 {
	margin: 0;
	color: #fff;
}

.white-box, article.post {
	background: #fff;
  margin: 1.6em 0;
  padding: 1.6em;
	-webkit-box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
	-moz-box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
	box-shadow: 0 3px 4px -3px rgba(0,0,0,.25);
}

@include media-breakpoint-up(md) {
	.white-box, article.post {
		margin: 3em 0;
		padding: 5em;
	}
}

/* Tables */
.thead-inverse th {
    background-color: #b20933;
}

/* Misc */
.vertical-align {
	display: flex;
  flex-direction: column;
  justify-content: center;
}

.nowrap {
	white-space: nowrap;
}

div.wpcf7-response-output {
	padding: .75rem 1.25rem;
	margin: 0 0 1rem 0;
	border: 1px solid transparent;
	border-radius: .25rem;
}

div.wpcf7-validation-errors {
	background-color: #f2dede;
	border-color: #ebcccc;
	color: #a94442;
}
