.content-info {
	padding-bottom: 3em;
	text-align: center;
}

.content-info img {
	margin-bottom: 15px;
}

.content-info .widget ul {
	padding: 0;
	list-style: none;
}

.widget_archive ul {
	text-transform: capitalize;
}

.copyright {
	color: #999;
	margin-top: .5em;
}

@include media-breakpoint-up(md) {
	.content-info {
		text-align: left;
	}
}