/* Start */
#logos {
	margin-top: 1.6em;
}

@include media-breakpoint-up(md) {
	#logos {
		margin-top: 3em;
	}
}

.home .white-box img {
	max-width: 100%;
  height: auto;
}

.item-value, .item-sold-value {
	font-weight: 600;
}

/* Bidragsgivare */
.contributors {
	margin-top: 1em;
}

.contributor-item {
	margin-bottom: 1em;
	text-align: center;
}

@include media-breakpoint-up(md) {
	.contributors {
		margin-top: 3em;
	}
	.contributor-item {
		margin-bottom: 3em;
	}
}

.item-buyer, .item-sold-value, .item-worth {
	display: block;
}

.item-buyer, .item-sold-value {
	color: #5cb85c!important;
}

.item-worth {
	color: #aaa;
	font-size: 0.8em;
	font-style: italic;
	margin-top: 0.3em;
}

/* Produkter */
.product-item {
	margin-bottom: 1em;
}

.product-item h3 {
	margin: .5em 0 0 0;
}

/* Auktion */
.auction-item {
	text-align: center;
}

.auction-item .auction-item-left {
	padding: 1em 0;
}

@include media-breakpoint-up(md) {
	.auction-item .auction-item-left {
		text-align: left;
		padding: 0;
	}
}

.auction-item .img-fluid {
	margin: 0 auto;
}